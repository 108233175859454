import styled from '@emotion/styled'
import { gsColors as c } from '../../../styles/Variables'
import { mq } from '../../../helpers'

export const FooterContainer = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 72px auto;

  ${mq('max', '768px')} {
    margin: 36px 0;
    padding: 0 16px;
  }

  .footer-top {
    display: flex;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 22px;

    ${mq('max', '970px')} {
      flex-direction: column;
      gap: 30px;
    }

    ${mq('max', '768px')} {
      padding: 20px 0 0 0;
    }

    .footer-right {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-left: 40px;
      border-left: 1px solid black;

      ${mq('max', '970px')} {
        border: none;
        padding-left: 0;
      }

      .link-sections {
        flex: 1;

        .sections-container {
          display: flex;
          gap: 20px;
          justify-content: space-between;

          ${mq('max', '768px')} {
            flex-direction: column;
            gap: 0px;
          }

        }
      }

      .footer-social {
        display: flex;
        gap: 8px;

        ${mq('max', '768px')} {
          margin: 36px 0; 
        }

        ${mq('max', '970px')} {
          margin-top: 20px;
        }
      }
    }

    .app-section {
      flex-basis: 40%;
      .left-header {
        max-width: 80%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 30px;
      }
    }
  }

  .footer-bottom-logo {
    margin-top: 72px; 

    ${mq('max', '768px')} {
      margin-top: 36px; 
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  
`

export const FooterLinkSection = styled.div`
  .section-header-wrapper {
    margin-bottom: 18px;

    button {
      display: none;
      border: none;
      background-color: transparent;
      font-size: 18px;
      font-family: Garnett;
      font-weight: 700;

      &:hover {
        cursor: pointer;
      }
    }

    ${mq('max', '768px')} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      button {
        display: block;
      }
    }
  }

  ul {
    maring-top: 18px;

    li {
      margin: 8px 0;
      a {
        text-decoration: none;
      }
    }
  }

  ${mq('max', '768px')} {
    border-top: 1px solid black;

    &:last-child {
      border-bottom: 1px solid black;
    }

    .hidden {
      display: none;
    }
  }
`

export const FooterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 50px 50px 0px;
  text-transform: uppercase;
  justify-content: center;
  margin-top: 80px;

  ${mq('max', '700px')} {
    flex-direction: column;
  }

  ${mq('max', '425px')} {
    padding: 50px 20px 0;
  }

  ${mq('min', '1600px')} {
    padding: 50px 80px 0px;
  }

  .footer-header {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 0 0 10px;
  }

  .center-line {
    display: flex;
    justify-content: center;
    flex-basis: 4%;
    margin: -20px 25px;
    height: 250px;

    div {
      background-color: #e5e5e5;
      width: 1px;
    }

    ${mq('max', '700px')} {
      display: block;
    }
  }

  .appstore-icon {
    margin-top: 1rem;
    height: 15%;

    ${mq('max', '700px')} {
      height: 25%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`

export const FooterRight = styled.div`
  flex-basis: 48%;
  display: flex;
  gap: 20px;

  .mobile-view-appstore-icon {
    display: none;
  }

  .footer-header {
    color: ${c.darkEggplant};
  }

  .mobile-header {
    color: ${c.sunsetOrange};
  }

  .link-section {
    flex-basis: 30%;
    ${mq('min', '1600px')} {
      flex-basis: 15%;
    }
  }

  a {
    font-size: 10px;
    text-decoration: none;
    color: ${c.darkEggplant};
    letter-spacing: 1px;

    &:hover {
      color: ${c.sunsetOrange};
    }
  }

  li {
    margin-bottom: 2px;
  }

  ${mq('max', '700px')} {
    flex-basis: 100%;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e5e5;
    gap: 0;

    .link-section {
      flex-basis: 50%;
      margin-bottom: 35px;
    }

    .mobile-view-appstore-icon {
      display: block;
      text-align: right;
    }

    .support {
      text-align: right;
    }
  }
`

export const FooterLeft = styled.div`
  flex-basis: 48%;
  display: flex;
  height: 100%;
  justify-content: flex-end;

  ${mq('max', '700px')} {
    display: none;
  }

  .app-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    ${mq('min', '1600px')} {
      width: 300px;
    }

    .app-header {
      color: ${c.sunsetOrange};
      text-transform: uppercase;
      font-size: 13px;
      margin-bottom: 10px;
    }

    .app-sub-header {
      font-size: 1.5rem;
      text-transform: capitalize;
      color: ${c.darkEggplant};
    }

    .sms-warning {
      text-transform: capitalize;
      font-size: 0.8rem;
      color: ${c.venus};
      flex: 0.5;
    }

    .phone-form {
      display: flex;
      margin: 18px 0 10px;

      input {
        border: none;
        border-bottom: 2px solid ${c.venus};
        flex-basis: 80%;
        margin-right: 20px;
        &:focus {
          outline: none;
        }

        ${mq('min', '1600px')} {
          display: block;
          flex-basis: 35%;
        }
      }
    }
  }

  .app-img  {
    margin-right: 20px;
    text-align: center;

    ${mq('max', '1024px')} {
      display: none;
    }
  }
`
