import React from 'react'
import { BannerWrapper } from './styles'

const Banner = (props) => {
  return (
    <BannerWrapper>
      {/* packages page UI is affected by this banner— ID is necessary for a hacky solution */}
      <div id='banner' style={{ display: 'flex', flexDirection: 'column' }}>
        {props.children}
      </div>
    </BannerWrapper>
  )
}

export default Banner