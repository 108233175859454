/**
 * Bugsnag 
 */
import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const ERROR_BOUNDARY_META_KEY = 'Error Boundary'

let initialized = false
const initialize = () => {
  if (typeof window === 'undefined') {
    return
  }  
  if (initialized) {
    return
  }
  initialized = true

  const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY

  // determine bugsnag stage based on URL
  const releaseStage = (() => {
    const host = window.location.host
    // localhost: development
    if (/^localhost(:\d+)?$/.test(host)) {
      return 'development'
    }

    // www-dev: staging
    if (/-dev\./.test(host)) {
      return 'staging'
    }

    // otherwise it's production
    return 'production'
  })()

  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    appVersion: process.env.NEXT_PUBLIC_VERSION,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: [
      'production',
      'staging',
      // 'development'
    ],
    releaseStage: releaseStage,
    onError: (event) => {
      if (!(event && event.errors)) {
        return false
      }

      if (
        event.errors.find((error) => error.errorMessage?.includes?.('Failed to fetch')) ||
        event.errors.find((error) => error.errorMessage?.includes?.('Minified React error')) ||
        event.errors.find((error) => error.errorMessage?.includes?.('Network error: Load failed')) ||
        event.errors.find((error) => error.errorMessage?.includes?.('Failed to load Stripe.js'))
      ) {
        return false
      }

      event.addMetadata(ERROR_BOUNDARY_META_KEY, {
        name: 'None: Global Error Handler'
      })
    },
    
  })
}

/**
 * setBugsnagUser: identifies user to Bugsnag
 *
 * @param {*} user
 */
export const setBugsnagUser = (user) => {
  initialize()
  Bugsnag.setUser(user.id, user.email, user.fullName)
}

/**
 * createBugsnagErrorBoundary: the error boundary to wrap the app in
 */
export const createBugsnagErrorBoundary = (boundaryName = 'Untitled Error Boundary') => ({ FallbackComponent, children }) => {
  initialize()

  if (typeof window === 'undefined') {
    return children
  }

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  // add boundary name to error
  const onError = (event) => {
    event.clearMetadata(ERROR_BOUNDARY_META_KEY)
    event.addMetadata(ERROR_BOUNDARY_META_KEY, {
      name: boundaryName
    })

    event.errors.forEach((error) => {
      error.errorMessage = `${boundaryName}: ${error.errorMessage}`
    })
  }

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
      {children}
    </ErrorBoundary>
  )
}
