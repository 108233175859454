/* eslint-disable indent */
import styled from '@emotion/styled'
import { gsColors as c } from '../styles/Variables'
import { mq } from '../helpers'

export const BubbleLink = styled.p`
  background-color: transparent;
  border: 1px solid ${c.lightTangerine};
  border-radius: 500px;
  padding: 6px 16px;
  box-sizing: border-box;
  color: ${c.meredithGrey};
  text-align: center;
  font-family: Euclid;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  min-width: max-content;

  &:hover, &.selected {
    background-color: ${c.sunsetOrange};
    color: #FFF;
    border: 1px solid ${c.sunsetOrange};
  }
`

export const HeroText = styled.span`
  font-family: Brown;
  text-shadow: ${props =>
    props.black ? 'none' : '0 2px 2px rgba(0, 0, 0, 0.25)'};
  font-size: ${props => (props.secondary ? '21px' : '62px')};
  font-weight: ${props => (props.secondary ? 'normal' : 'bold')};
  font-stretch: normal;
  font-style: normal;
  line-height: ${props => (props.secondary ? '1.24' : '1.13')};
  letter-spacing: normal;
  color: ${props => (props.black ? c.black : c.white)};

  ${mq('max', '485px')} {
    font-size: ${props => (props.secondary ? '18px' : '42px')};
    letter-spacing: ${props => (props.secondary ? 'normal' : '-1')};
  }
`

export const Title = styled.h3`
  width: auto;
  // height: ${props => (props.secondary ? '15px' : '42px')};
  font-family: Brown;
  font-size: ${props => (props.secondary ? '21px' : '36px')};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: ${props => (props.secondary ? '1.24' : '1.17')};
  letter-spacing: ${props => (props.secondary ? '0.4px' : '-1.29px')};
  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : c.black)};
  margin: 0;
`

/*
***HEADERS 1-3***

** FLAGS

Header1:
white - makes text white, defaults to black (header1 can only be white or black)

All Headers:
align - pass in 'left', 'right' or 'center', defaults left
shadow - applies text shadow, standardized across all headers
color - pass in string (any color) for text.  Does not apply to Header1
margin - pass in a string to override margin

*/

export const Header1 = styled.h1`
  font-size: 54px;
  font-weight: 750;
  line-height: 140%;
  font-family: Garnett;
  text-align: ${props => (props.align ? props.align : 'left')};
  text-shadow: ${props =>
    props.shadow ? '0 2px 2px rgba(0, 0, 0, 0.25)' : 'none'};
  color: ${props => (props.color ? props.color : (props.white ? c.white : c.black))};
  margin: ${props => (props.margin ? props.margin : '0')};
  ${mq('max', '768px')} {
    font-size: 28px;
  }
`

export const Header2 = styled.h2`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '46px')};
  font-weight: 750;
  line-height: ${props => (props.lineHeight ? props.lineHeight : '1.17')};
  font-family: Garnett;
  text-align: ${props => (props.align ? props.align : 'left')};
  margin: ${props => (props.margin ? props.margin : '0')};
  text-shadow: ${props =>
    props.shadow ? '0 2px 2px rgba(0, 0, 0, 0.25)' : 'none'};
  color: ${props => (props.color ? props.color : c.black)};

  ${mq('max', '768px')} {
    font-size: 25px;
  }
`

export const Header3 = styled.h3`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '20px')};
  font-family: Euclid;
  font-weight: ${props => (props.weight ? props.weight : 600)};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '1.24')};
  letter-spacing: ${props => (props.spacing ? `${props.spacing}px` : '0.4px')};
  text-align: ${props => (props.align ? props.align : 'left')};
  margin: ${props => (props.margin ? props.margin : '0')};
  text-shadow: ${props =>
    props.shadow ? '0 2px 2px rgba(0, 0, 0, 0.25)' : 'none'};
  color: ${props => (props.color ? props.color : c.black)};

  ${mq('max', '768px')} {
    font-size: ${props => (props.fontSize ? `${props.fontSize * 0.75}px` : '14px')};
  }
`

/* 
***SECTION HEADER***
- purple wide spaced lettering for service types (Introductory Offer / wedding services)
*/

export const SectionHeader = styled.h4`
  margin: ${props => (props.margin ? props.margin : '0')};
  font-size: ${props => (props.small ? '13px' : '21px')};
  letter-spacing: 3.5px;
  color: ${c.cobalt};
  line-height: 1.38;
  font-family: Euclid;
  text-transform: uppercase;
  text-align: center;
  // font-weight: ${props => (props.small ? '700' : 'normal')};
  font-weight: 400;
  border-bottom: ${props =>
    props.selected ? `solid 1px ${c.cobalt}` : ''};

  line-height: 18px;
  letter-spacing: 0.52px;

  ${mq('max', '768px')} {
    letter-spacing: normal;
    margin: 1rem 0;
  }
`

/*
***TEXT***

variant - pass in 'large' or 'small'— defaults to 16px if variant not selected
fontSize - pass in a number. will override variant and just set the font, use sparingly
margin - pass in string to override margin
color - pass in string (any color) for text
spacing - pass in number (pixels) to override letter spacing
weight - pass in number to override font weight
shadow - adds shadow

*/
export const Text = styled.p`
  font-size: ${props => {
    if (props.fontSize) {
      return `${props.fontSize}px`
    }
    switch (props.variant) {
      case 'large':
        return '38px'
      case 'medium':
        return '20px'
      case 'small':
        return '16px'
      default:
        return '16px'
    }
  }};
  font-weight: ${props => (props.weight ? props.weight : 400)};
  font-family: ${props => (props.font ? props.font : 'Euclid')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '130%')};
  text-shadow: ${props =>
    props.shadow ? '0 2px 2px rgba(0, 0, 0, 0.25)' : 'none'};
  letter-spacing: ${props => (props.spacing ? `${props.spacing}px` : '0.16px')};
  margin: ${props => (props.margin ? props.margin : '0')};
  color: ${props => (props.color ? props.color : c.black)};

  ${props => props.whiteSpace ? `white-space: ${props.whiteSpace};` : ''}

  ${mq('max', '768px')} {
    font-size: ${props => {
    if (props.fontSize) {
      return `${props.fontSize * 0.75}px`
    }
    switch (props.variant) {
      case 'large':
        return '20px'
      case 'medium':
        return '16px'
      case 'small':
        return '13px'
      default:
        return '13px'
    }
  }};
}

`

export const Input = styled.input`
  border: none;
  border-bottom: 0.5px solid ${c.paleSlate};
  font-family: Brown;
  line-height: 1.7rem;
  width: 100%;
  transition: all 0.5s ease;
  &::placeholder {
    color: ${c.paleSlate};
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid ${c.black};
  }
`

/*
**LINK/BUTTON PROPS/FLAGS**

  *flags*
  large - sized for hero images / large banners
  outline - creates an outline button, defaults to honeyflower
  fill - button will take up entire width of container
  noBorder - only works with outline buttons, removes border/hover fill for static text link

  *props*
  color - takes a string (any color) as a prop, adjusts background/border color, text on outline button
  margin - takes a string as custom margin property

*/

export const LinkButton = styled.a`
  position: relative;
  box-sizing: border-box;
  font-family: Euclid;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.5s ease, color 0.25s ease;
  letter-spacing: ${props => (props.large ? '0.94px' : 'normal')};
  font-weight: 600;
  border: ${props =>
    props.noBorder
      ? 'none'
      : `solid 1px ${props.color ? props.color : c.sunsetOrange}`};
  font-size: ${props => (props.large ? '18px' : '13px')};
  color: ${props =>
    props.outline ? (props.color ? props.color : c.sunsetOrange) : c.white};
  min-width: ${props => (props.large ? '200px' : '155px')};
  min-height: 48px;
  // padding: ${props => (props.large ? '23px 18px 19px' : '14px 11px 12px')};
  padding: 14px 50px;
  background-color: ${props =>
    props.outline ? 'transparent' : props.color ? props.color : c.sunsetOrange};
  width: ${props => (props.fill ? '100%' : 'max-content')};
  margin: ${props => (props.margin ? props.margin : '0')};

  &:hover {
    cursor: pointer;
    ${props =>
    props.outline && !props.noBorder
      ? 'border: 1px solid #999;'
      : ''}
  }

  &:disabled {
    opacity: ${props => (props.outline ? '.7' : '1')};
    border: ${props =>
    props.outline
      ? `solid 1px ${props.color ? props.color : c.sunsetOrange}`
      : 'none'};

    &::after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &:hover {
      cursor: initial;
      background-color: ${props =>
    props.outline
      ? 'transparent'
      : props.color
        ? props.color
        : c.sunsetOrange};
      color: ${props =>
    props.outline ? (props.color ? props.color : c.sunsetOrange) : c.white};
    }
  }

  ${mq('max', '768px')} {
    font-size: ${props => (props.large ? '14px' : '13px')};
    // min-width: ${props => (props.large ? '262px' : '155px')};
    // padding: ${props => (props.large ? '18px 18px 14px' : '14px 11px 12px')};
  }
`

export const UnderlineButton = styled.button`
  font-size: 18px;
  font-family: Euclid;
  font-weight: 600;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid black;
  padding: 0;
  margin: 0;
  letter-spacing: 1.44px; 

  ${mq('max', '768px')} {
    font-size: 14px;
    letter-spacing: 1.12px;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Button = styled.button`
  position: relative;
  box-sizing: border-box;
  font-family: Euclid;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  // transition: background-color 0.5s ease, color 0.25s ease;
  letter-spacing: ${props => (props.large ? '0.94px' : 'normal')};
  font-weight: 600;
  border: ${props =>
    props.noBorder
      ? 'none'
      : `solid 1px ${props.color ? props.color : c.sunsetOrange}`};
  font-size: ${props => (props.large ? '18px' : '13px')};
  color: ${props =>
    props.outline ? (props.color ? props.color : c.sunsetOrange) : c.white};
  min-width: ${props => (props.large ? '200px' : '155px')};
  min-height: 48px;
  // padding: ${props => (props.large ? '23px 18px 19px' : '14px 11px 12px')};
  padding: ${props => (props.padding ? props.padding : '14px 50px')};
  background-color: ${props =>
    props.outline ? 'transparent' : props.color ? props.color : c.sunsetOrange};
  width: ${props => (props.fill ? '100%' : 'max-content')};
  margin: ${props => (props.margin ? props.margin : '0')};

  &:hover {
    cursor: pointer;
    ${props =>
    props.outline && !props.noBorder
      ? 'border: 1px solid #999;'
      : ''}

    ${props => props.hover ? `background-color: ${c.tangerineHover};` : ''}
    ${props => props.hover ? `border-color: ${c.tangerineHover};` : ''}
  }

  &:disabled {
    opacity: ${props => (props.outline ? '.7' : '1')};
    border: ${props =>
    props.outline
      ? `solid 1px ${props.color ? props.color : c.sunsetOrange}`
      : 'none'};

    &::after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &:hover {
      cursor: initial;
      background-color: ${props =>
    props.outline
      ? 'transparent'
      : props.color
        ? props.color
        : c.sunsetOrange};
      color: ${props =>
    props.outline ? (props.color ? props.color : c.sunsetOrange) : c.white};
    }
  }

  ${mq('max', '768px')} {
    font-size: ${props => (props.large ? '14px' : '13px')};
    // min-width: ${props => (props.large ? '262px' : '155px')};
    // padding: ${props => (props.large ? '18px 18px 14px' : '14px 11px 12px')};
  }
`

export const AuthPageWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 50 auto;
  display: flex;
  flex-direction: column;

  ${mq('max', '768px')} {
    width: 80vw !important;
  }

  ${mq('max', '550px')} {
    width: 100vw !important;
  }

  form {
    ${mq('max', '768px')} {
      width: 100%;
      max-width: none;
      min-width: 100%;
    }
  }
`

export const H1_Base = styled.h1`
  font-size: 21px;
  line-height: 1.2;
  letter-spacing: -0.3px;
`

export const H2_Base = styled.h2`
  color: ${c.saltBox};
  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
`

// Used mainly in booking flow steps
export const Section = styled.section`
  margin: 0 16px 30px;
`

export const ReferralWrapper = styled.div`
  margin: 50px auto;
  max-width: 1080px;
  
  .referral-content {
    padding: 30px;
    display: flex;
    gap: 30px;

    ${mq('max', '625px')} {
      flex-direction: column;
    }
  }

  .referral-copy {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;

    div:nth-of-type(2) {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .logo-container {
      width: 50%;

      img {
        max-width: 100%;
        height: 100%;

        ${mq('max', '625px')} {
          margin-bottom: 50px;
        }
      }
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    justify-content: flex-end;

    ${mq('max', '625px')} {
      justify-content: center;
    }

    h1 {
      display: none;
    }

    & > div {
      display: none;
    }

    section {
      margin: 0;
      padding: 20px;
      width: 100%;

      ${mq('max', '625px')} {
        width: 100%;
      }
    }
  }
`
