import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useAllMarkets } from '../services'
import { storageMode, findClosestMarketFromIp } from '../helpers'
import { growthbook } from '../services/growthbook'

export const MarketContext = React.createContext({})
export const useMarketContext = () => useContext(MarketContext)

export const MarketContextProvider = ({ children }) => {
  const [selectedMarket, _setSelectedMarket] = useState(null)
  const markets = useAllMarkets()
  const storage = storageMode('localStorage')

  // wrapper around _setSelectedMarket to update local storage
  // as well as context state.
  const setSelectedMarket = useCallback(
    (market) => {
      // once selected, save market in browser for 1 week
      let date = new Date()
      date = date.setDate(date.getDate() + 1 * 7).toString()
      storage.setItem(
        'currentMarket',
        JSON.stringify({ exp: date, id: market.id })
      )


      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        market: market.shortName
      })
      return _setSelectedMarket(market)
    },
    [storage]
  )

  useEffect(() => {
    if (!markets || !setSelectedMarket) {
      return
    }

    let now = new Date()
    now = now.getTime()

    // check for market in local storage
    let savedMarket
    try {
      savedMarket = JSON.parse(storage.getItem('currentMarket'))
    } catch (error) {
      console.warn('error parsing saved market', error)
    }

    if (savedMarket) {
      if (Number(savedMarket.exp) < now) {
        // if time expired, remove local storage, find market based on IP address
        storage.removeItem('currentMarket')
        findClosestMarketFromIp(markets, setSelectedMarket)
      } else {
        // find market by matching ID and set
        savedMarket = markets.find((m) => m.id === savedMarket.id)
        setSelectedMarket(savedMarket)
      }
    } else {
      findClosestMarketFromIp(markets, setSelectedMarket)
    }
  }, [markets, setSelectedMarket, storage])

  return (
    <MarketContext.Provider
      value={{ markets: markets || [], selectedMarket, setSelectedMarket }}
    >
      {children}
    </MarketContext.Provider>
  )
}
