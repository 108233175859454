import gql from 'graphql-tag';

// Define fragments available to other documents
const fragmentsArray = [
  gql`
    fragment BaseUser on User {
      id
      nameFirst
      nameLast
      fullName
      phone
      phoneType
      email
      createdAt
      isMember
      isVIP
      typeOfVIP
      typeOfVIPOther
      discount
      referralUrl
      discount
      shouldAutoCloseAppointments
      stripeCustomerId
      lastAppointmentBookedAt
      isAdmin
      isProvider
      isOps
      isClientService
      isMarketing
      isLocked
      appointmentCount
    }
  `,
  gql`
    fragment OrderAttributes on Order {
      id
      userId
      transactions(where: { erroredAt: null }) {
        id
        createdAt
        paymentMethod {
          id
        }
        erroredAt
        amount
        total
      }
      isCancelled
      shopifyOrderId
      createdAt
      trackingUrl
      trackingNumber
      subtotal
      shippingFee
      tax
      total
      shipmentStatus
    }
  `,
  gql`
    fragment AppointmentServiceAttributes on AppointmentService {
      id
      startDateTime
      appointmentId
      appointment {
        ownerId
      }
      price
      duration
      service {
        id
        serviceType {
          id
          providerTitle
        }
      }
      baseService {
        id
        name
      }
      addOnServices {
        id
        addOnName
      }
      provider {
        userId
        fullName
        nameLast
        nameFirst
        photoUrls
      }
    }
  `,
  gql`
    fragment MarketFields on Market {
      id
      name
      shortName
      isEnabled
      timezone
      cancellationPolicy
      serviceTax
      productTax
      tip
      handlingFee
      addressCompletionOptions
      priority
      minimumAdvanceBookingMinutes
      cutoffTime
      resumeTime
      latitude
      longitude
      addressCompletionOptions
      firstAppointmentTime
      lastAppointmentTime
    }
  `
];

const FRAGMENTS = fragmentsArray.reduce((accumulator, fragment) => {
  // Assuming the 'fragment' variable is the AST returned by gql and it has the expected structure.
  const fragmentName = fragment.definitions[0].name.value;
  if (!fragmentName) {
    throw new Error('Missing fragment name');
  }
  if (fragment.definitions[0].kind !== 'FragmentDefinition') {
    throw new Error('Must define a fragment');
  }
  accumulator[fragmentName] = fragment; // Key the fragment by its name
  return accumulator;
}, {})

;export const USER_FACET_SEARCH = gql`
  query ($where: SequelizeJSON, $order: String!, $limit: Int!, $offset: Int!) {
    users(where: $where, order: $order, limit: $limit, offset: $offset) {
      ...BaseUser
    }
  }
  ${FRAGMENTS.BaseUser}
`

export const LOAD_USER = gql`
  query ($where: SequelizeJSON) {
    users(where: $where) {
      ...BaseUser
    }
  }
  ${FRAGMENTS.BaseUser}
`

export const UPDATE_USER = gql`
  mutation ($id: String!, $user: UserInput!) {
    updateUser(id: $id, user: $user) {
      ...BaseUser
    }
  }
  ${FRAGMENTS.BaseUser}
`

export const FIND_CLIENT = gql`
  query ($where: SequelizeJSON) {
    users(where: $where) {
      ...BaseUser
      createdAt
      discount
      referralUrl
      shouldAutoCloseAppointments
      stripeCustomerId
    }
  }
  ${FRAGMENTS.BaseUser}
`

export const CLIENT_SEARCH = gql`
  query ($where: SequelizeJSON, $appointmentsWhere: SequelizeJSON) {
    users(where: $where, order: "fullName", limit: 50) {
      ...BaseUser
      addresses {
        id
        marketId
        zoneId
        street
        apartment
        city
        state
        zip
        neighborhood
      }
      appointments(
        limit: 1
        order: "actualStartDateTime"
        where: $appointmentsWhere
      ) {
        actualStartDateTime
        address {
          market {
            timezone
          }
        }
      }
    }
  }
  ${FRAGMENTS.BaseUser}
`

export const CLIENT_ORDER_SEARCH = gql`
  query ($where: SequelizeJSON) {
    orders(where: $where, order: "reverse:createdAt") {
      ...OrderAttributes
    }
  }
  ${FRAGMENTS.OrderAttributes}
`

export const PLACE_ORDER = gql`
  mutation ($order: OrderInput!) {
    createOrder(order: $order) {
      ...OrderAttributes
    }
  }
  ${FRAGMENTS.OrderAttributes}
`
export const CANCEL_ORDER = gql`
  mutation ($id: String!, $order: OrderInput!) {
    updateOrder(id: $id, order: $order) {
      ...OrderAttributes
    }
  }
  ${FRAGMENTS.OrderAttributes}
`

export const ORDER = gql`
  query ($id: String!) {
    order(id: $id) {
      ...OrderAttributes
    }
  }
  ${FRAGMENTS.OrderAttributes}
`

export const CREATE_TRANSACTION = gql`
  mutation ($transaction: TransactionInput!) {
    createTransaction(transaction: $transaction) {
      id
    }
  }
`

export const USER_API_REQUESTS = gql`
  query ($userId: String!, $offset: Int!, $limit: Int!) {
    user(id: $userId) {
      apiRequests(limit: $limit, offset: $offset, order: "reverse:createdAt") {
        createdAt
        statusCode
        method
        originalUrl
        clientApplication {
          description
        }
      }
    }
  }
`

export const ME = gql`
  query {
    me {
      id
      ...BaseUser
      isPhoneValid
      isPhoneSmsCapable
      isInServiceArea
      referralCode
      credits
      marketingId
      receiveMarketingEmails
      hasSquad
      photoUrls
      tier
      isEligibleForSameDayAppointments
      updatedAt
      registrationClientApplicationId
      defaultPaymentMethodId
      appointments(
        where: { isCanceled: false }
        order: "reverse:createdAt"
        limit: 50
      ) {
        id
        actualStartDateTime
        actualEndDateTime
        isCanceled
        totalPrice
        finalPriceWithTaxAndTip
        taxAmount
        tipAmount
        creditsApplied
        handlingFee
        notes
        transactions(where: { erroredAt: null }) {
          id
          amount
          tax
          tip
          handlingFee
          total
        }
        address {
          id
          type
          street
          apartment
          city
          state
          zip
          pets
          notes
          market {
            id
            timezone
            serviceTax
            ...MarketFields
          }
        }
        appointmentServices {
          id
          duration
          price
          customPrice
          baseService {
            id
            name
            serviceType {
              id
              name
            }
          }
          addOnServices {
            id
            name
            price
            description
          }
          provider {
            photoUrls
            nameFirst
            nameLast
          }
        }
      }
      addresses(order: "reverse:createdAt") {
        id
        street
        apartment
        city
        state
        zip
        notes
        type
        pets
        marketId
        latitude
        longitude
      }
      paymentMethods {
        id
        brand
        type
        number
        fullName
        expirationMonth
        expirationYear
      }
      memberships(
        where: { isActive: true }
        order: "reverse:createdAt"
        limit: 1
      ) {
        id
        isActive
        isPaused
        pausedUntil
        lastRenewedAt
        renewalPeriod
        createdAt
        product {
          id
          name
        }
      }
      userCredits {
        id
        amount
        isMembership
        isPaid
        isExpired
        amountUsed
        amountRemaining
        claimCode
        service {
          name
          id
        }
      }

      referrals(order: "reverse:createdAt", limit: 100) {
        id
        referrerRewarded
        rewardAmount
        recipient {
          fullName
          email
        }
      }
    }
  }
  ${FRAGMENTS.BaseUser}
  ${FRAGMENTS.MarketFields}
`

export const LOAD_MARKETS = gql`
  query {
    markets {
      ...MarketFields
    }
  }
  ${FRAGMENTS.MarketFields}
`

export const LOAD_MARKET_SERVICES_WITH_ADD_ONS = gql`
  query (
    $marketServicesWhere: SequelizeJSON
    $addOnMarketServicesWhere: SequelizeJSON
  ) {
    marketServices(where: $marketServicesWhere) {
      id
      isPopular
      marketId
      price
      duration
      informationalPriceText
      service {
        id
        name
        description
        priority
        isInformational
        informationalUrl
        maxPerAppointment
        infoHowToPrep
        infoWhatsIncluded
        serviceType {
          id
          name
          priority
        }
      }
      addOnMarketServices(where: $addOnMarketServicesWhere) {
        id
        addOnPrice
        addOnDuration
        service {
          id
          addOnName
          addOnDescription
          priority
        }
      }
    }
  }
`

export const FETCH_MARKET = gql`
  query ($id: String!) {
    market(id: $id) {
      ...MarketFields
    }
  }
  ${FRAGMENTS.MarketFields}
`

export const APPOINTMENT_SERVICES = gql`
  query ($where: SequelizeJSON, $limit: Int, $offset: Int) {
    appointmentServices(
      where: $where
      limit: $limit
      offset: $offset
      order: "reverse:startDateTime"
    ) {
      id
      providerPositions
      provider {
        id
      }
      service {
        id
        name
        serviceType {
          id
        }
      }
      appointment {
        id
        startDateTime
        owner {
          id
        }
        address {
          latitude
          longituded
          googlePlaceId
          market {
            id
            name
            timezone
          }
        }
      }
    }
  }
`

export const SERVICE_TYPES = gql`
  query {
    serviceTypes {
      id
      name
      priority
    }
  }
`

export const LOAD_SERVICES = gql`
  query {
    services {
      id
      name
      description
      price
      duration
      isAddOn
      isPureAddOn
      isCombined
      combinedAddOnServiceCompositeId
      addOnName
      addOnDescription
      addOnPrice
      addOnDuration
      maxPerAppointment
      backToBackOnly
      firstTimeBooking
      image
      thumbnailImage
      instructionalImage
      infoHowToPrep
      infoWhatsIncluded
      infoTagline
      baseResourceUrl
      priority
    }
  }
`

export const PRODUCTS = gql`
  query ($where: SequelizeJSON) {
    products(where: $where, order: "name") {
      id
      name
      sku
      isEnabled
      isPhysicalProduct
      category
      price
      isMembership
      renewalPrice
      creditAmount
      serviceType {
        name
      }
    }
  }
`

export const ADDRESSES = gql`
  query ($where: SequelizeJSON) {
    addresses(where: $where) {
      id
      street
      apartment
      city
      state
      zip
    }
  }
`

export const CREATE_ADDRESS = gql`
  mutation ($address: AddressInput!) {
    createAddress(address: $address) {
      id
      userId
      street
      apartment
      city
      state
      zip
      type
      notes
      pets
      latitude
      longitude
      marketId
    }
  }
`

export const UPDATE_ADDRESS = gql`
  mutation ($id: String!, $address: AddressInput!) {
    updateAddress(id: $id, address: $address) {
      id
      userId
      street
      apartment
      city
      state
      zip
      type
      notes
      pets
      latitude
      longitude
      marketId
    }
  }
`

export const UPDATE_APPOINTMENT = gql`
  mutation ($id: String!, $appointment: AppointmentInput!) {
    updateAppointment(id: $id, appointment: $appointment) {
      id
      notes
      actualStartDateTime
      actualEndDateTime
    }
  }
`

export const UPDATE_APPOINTMENT_SERVICE = gql`
  mutation ($id: String!, $appointmentService: AppointmentServiceInput!) {
    updateAppointmentService(id: $id, appointmentService: $appointmentService) {
      id
      duration
      price
      customPrice
      baseService {
        id
        name
        serviceType {
          id
          name
        }
      }
      addOnServices {
        id
        name
        price
        description
      }
      provider {
        photoUrls
        nameFirst
        nameLast
      }
    }
  }
`
export const DESTROY_APPOINTMENT_SERVICE = gql`
  mutation ($id: String!) {
    destroyAppointmentService(id: $id) {
      id
    }
  }
`

export const DESTROY_ADDRESS = gql`
  mutation ($id: String!) {
    destroyAddress(id: $id) {
      id
    }
  }
`

export const CREATE_PAYMENT_METHOD = gql`
  mutation ($paymentMethod: PaymentMethodInput!) {
    createPaymentMethod(paymentMethod: $paymentMethod) {
      id
      brand
      type
      fullName
      expirationMonth
      expirationYear
      number
    }
  }
`

export const DESTROY_PAYMENT_METHOD = gql`
  mutation ($id: String!) {
    destroyPaymentMethod(id: $id) {
      id
    }
  }
`

export const CREATE_USER_CREDIT = gql`
  mutation ($userCredit: UserCreditInput!) {
    createUserCredit(userCredit: $userCredit) {
      userId
      claimCode
      amount
      validUntil
    }
  }
`

export const PAYMENT_METHODS = gql`
  query ($where: SequelizeJSON) {
    paymentMethods(where: $where) {
      id
      brand
      type
      number
    }
  }
`

export const SHIPPING_RATES = gql`
  query {
    shippingRates {
      id
      name
      price
    }
  }
`

export const AT_RISK_APPOINTMENT_SERVICES = gql`
  query ($where: SequelizeJSON) {
    appointmentServices(where: $where) {
      id
      lateRiskLabel
      lateRiskScore
      lateRiskResolved
    }
  }
`

export const OWNER_API_REQUESTS = gql`
  query ($where: SequelizeJSON, $limit: Int, $offset: Int, $order: String) {
    apiRequests(where: $where, limit: $limit, offset: $offset, order: $order) {
      id
      createdAt
      method
      originalUrl
      bodyUrl
      bodyKey
      statusCode
      gqlQueryInformation
      responseTime
      clientApplication {
        description
      }
    }
  }
`

export const OWNER_COMMUNICATION_LOG = gql`
  query ($where: SequelizeJSON, $limit: Int, $offset: Int, $order: String) {
    communicationLogs(
      where: $where
      limit: $limit
      offset: $offset
      order: $order
    ) {
      id
      createdAt

      type

      subject
      body
      html

      error
      isErrored
      userId

      externalChannel
      externalId
      externalCampaignId
      externalAddress

      appointmentId
      appointmentServiceId

      appointmentService {
        id
        appointmentId
        startDateTime
        baseService {
          name
        }
        addOnServices {
          name
        }
        appointment {
          id
          actualStartDateTime
          address {
            market {
              timezone
            }
          }
        }
      }
    }
  }
`

export const OWNER_LOG_INS = gql`
  query ($where: SequelizeJSON, $limit: Int, $offset: Int, $order: String) {
    ownerLogins: accessTokens(
      where: $where
      limit: $limit
      offset: $offset
      order: $order
    ) {
      createdAt
      userAgent
      clientApplication {
        shortName
        description
      }
    }
  }
`

export const AT_RISK_APPOINTMENT_SERVICE_SUBSCRIPTION = gql`
  subscription ($filter: JSON) {
    appointmentServiceEvent(filter: $filter) {
      appointmentService {
        id
        lateRiskLabel
        lateRiskScore
        lateRiskResolved
      }
    }
  }
`

export const OWNER_EMAILS = gql`
  query ($where: SequelizeJSON, $limit: Int, $offset: Int, $order: String) {
    emails(where: $where, limit: $limit, offset: $offset, order: $order) {
      id
      createdAt
      subject
      sentAt
    }
  }
`

export const OWNER_APPOINTMENTS = gql`
  query ($where: SequelizeJSON, $limit: Int, $offset: Int, $order: String) {
    appointments(where: $where, limit: $limit, offset: $offset, order: $order) {
      id
      createdAt
      startDateTime
      isCanceled
      isPaymentReceived
      ownerId
      address {
        market {
          timezone
        }
      }
      appointmentServices {
        isProviderLocked
        baseService {
          name
        }
        addOnServices {
          name
        }
        provider {
          fullName
        }
      }
    }
  }
`

export const APPOINTMENT_DETAILS = gql`
  query ($appointmentId: String!) {
    id
    appointment(id: $appointmentId) {
      owner {
        id
        email
        fullName
      }
      address {
        street
        city
        state
        zip
        zone {
          id
          name
          market {
            id
            shortName
            name
            timezone
          }
        }
      }
      actualStartDateTime
      price
      appointmentServices {
        ...AppointmentServiceAttributes
      }
      surveyAnswers {
        __typename
        id
        value
        valueText
        question {
          __typename
          id
          value
          questionGroup {
            __typename
            name
            by
          }
        } 
      }
    }
  }
  ${FRAGMENTS.AppointmentServiceAttributes}
`

export const APPOINTMENT_SERVICE_DETAILS = gql`
  query ($appointmentServiceId: String!) {
    appointmentService(id: $appointmentServiceId) {
      ...AppointmentServiceAttributes
    }
  }
  ${FRAGMENTS.AppointmentServiceAttributes}
`

export const CREATE_APPOINTMENT = gql`
  mutation ($appointment: AppointmentInput!) {
    createAppointment(appointment: $appointment) {
      address {
        street
        city
        state
        zip
      }
      actualStartDateTime
      totalPrice
      finalPrice
    }
  }
`

export const CREATE_SURVEY_ANSWER = gql`
  mutation($surveyAnswer: SurveyAnswerInput!) {
    createSurveyAnswer(surveyAnswer: $surveyAnswer) {
      id
      appointmentId
      surveyQuestionId
      value
      appointmentServiceId
      resultingUserCredit {
        amount
      }
    }
  }
`

export const UPDATE_SURVEY_ANSWER = gql`
  mutation($id: String!, $surveyAnswer: SurveyAnswerInput!) {
    updateSurveyAnswer(id: $id, surveyAnswer: $surveyAnswer) {
      id
      appointmentId
      surveyQuestionId
      value
      appointmentServiceId
      resultingUserCredit {
        amount
      }
    }
  }
`

export const LOAD_MEMBERSHIPS = gql`
  query {
    products(
      where: { isMembership: true, isEnabled: true }
      order: "priority"
    ) {
      id
      name
      description
      price
      discountLabel
      priority
      renewalPrice
      renewalPeriod
      prepaidRenewalPeriods

      markets {
        id
      }
    }
  }
`

export const LOAD_PACKAGES = gql`
  query ($marketId: String!) {
    market(id: $marketId) {
      name
      id
      products(
        where: {
          isMembership: false
          isPhysicalProduct: false
          isEnabled: true
        }
        order: "priority"
      ) {
        id
        sku
        name
        description
        price
        discountLabel
        priority
        creditAmount
        productGroup {
          id
          name
          isEnabled
          priority
        }
      }
    }
  }
`

export const LOAD_USER_APPOINTMENTS = gql`
  query {
    me {
      id
      nameFirst
      appointments(where: { isCanceled: false }, order: "reverse:createdAt") {
        id
        actualStartDateTime
        actualEndDateTime
        isCanceled
        totalPrice
        finalPriceWithTaxAndTip
        taxAmount
        tipAmount
        creditsApplied
        handlingFee
        notes
        transactions(where: { erroredAt: null }) {
          id
          amount
          tax
          tip
          handlingFee
          total
        }
        address {
          id
          type
          street
          apartment
          city
          state
          zip
          pets
          notes

          market {
            timezone
            serviceTax
          }
        }
        appointmentServices {
          id
          duration
          price
          customPrice
          baseService {
            name
          }
          addOnServices {
            name
          }
          provider {
            photoUrls
            nameFirst
            nameLast
          }
        }
      }
    }
  }
`

export const LOAD_APPOINTMENTS = gql`
  query($where: SequelizeJSON, $limit: Int, $offset: Int, $order: String ) {
    appointments(where: $where, limit: $limit, offset: $offset, order: $order) {
      __typename
      id
      actualStartDateTime
      actualEndDateTime
      startDateTime
      isCanceled
      totalPrice
      finalPriceWithTaxAndTip
      taxAmount
      tipAmount
      creditsApplied
      handlingFee
      notes
      ownerId
      transactions(where: { erroredAt: null }) {
        __typename
        id
        amount
        tax
        tip
        handlingFee
        erroredAt
        total
      }
      address {
        __typename
        id
        type
        street
        apartment
        city
        state
        zip
        pets
        notes
        market {
          __typename
          id
          timezone
          serviceTax
          # ...MarketFields
        }
      }
      appointmentServices {
        __typename
        id
        duration
        startDateTime
        price
        customPrice
        timezone
        providerConfirmedAt
        providerId
        isSquadSearch
        baseService {
          __typename
          id
          name
          serviceType {
            __typename
            id
            name
          }
        }
        addOnServices {
          __typename
          id
          name
          price
          description
        }
        provider {
          __typename
          photoUrls
          photoUrl
          nameFirst
          nameLast
        }
      }
    }
  }
`

export const LOAD_GIFT_CARD = gql`
  query {
    products(where: { category: "Gift Certificate", name: "Custom Amount" }) {
      id
      name
    }
  }
`

export const LOAD_SERVICES_FOR_MARKET = gql`
  query ($id: String!) {
    market(id: $id) {
      __typename
      id
      name
      marketServices(where: { isAddOn: false }) {
        __typename
        id
        price
        duration
        service {
          __typename
          id
          priority
          name
          description
          isInformational
          informationalUrl
          informationalPriceText
          maxPerAppointment
          infoHowToPrep
          infoWhatsIncluded
          isPopular
          serviceType {
            id
            name
            priority
            serviceInfoTitle
            serviceInfoBody
          }
        }

        addOnMarketServices(where: { isPureAddOn: true }) {
          __typename
          id
          service {
            __typename
            id
            priority
            duration
            price
            addOnDuration
            addOnPrice
            name
            description
          }
        }
      }
    }
  }
`

export const LOAD_CLIENT_SURVEY_QUESTIONS = gql`
  query{
    surveyQuestionGroups {
      __typename
      id
		  name
		  by
		  for
      questions(where: { isEnabled: true}) {
        __typename
        id
        isEnabled
        type
        value
        isFloat
        priority
        questionGroup {
          __typename
          name
        }
      }
    }
  }
`

export const FETCH_PRODUCT = gql`
  query ($id: String!) {
    product (id: $id) {
      __typename
      id
      name
      discountLabel
      sku
      description
      isEnabled
      category
      price
      isMembership
      renewalPrice
      creditAmount
      serviceType {
        name
      }
    }
  }
`

export const GET_BANNER = gql`
  query ($clientApplicationId: String!) {
    getBanner (clientApplicationId: $clientApplicationId) {
      banners {
        id
        text
        secondaryText
        link
        startDateTime
        endDateTime
        isDefault
        defaultArchivedAt
        clientApplicationId
      }
      defaultBanner {
        id
        text
        secondaryText
        link
        startDateTime
        endDateTime
        isDefault
        defaultArchivedAt
        clientApplicationId
      }
    }
  }
`
