/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react'
import { FooterContainer, FooterLinkSection } from './styles'
import { Header2, Header3, Text } from '../../styles/Components'
import { AppStoreIcon } from './app-store-icon'
import Link from 'next/link'
import { serviceUrls, supportUrls, aboutUrls } from './url-data'
import { InstagramIcon, FacebookIcon, TikTokIcon, LinkedInIcon } from './social-icons'
import { useRouter } from 'next/router'

const Footer = ({ forceShow = false }) => {
  const appUrl = 'https://apps.apple.com/us/app/glamsquad/id766676007?ign-mpt=uo%3D4'
  const socialLinks = {
    instagram: 'https://www.instagram.com/glamsquad/',
    facebook: 'https://www.facebook.com/GlamSquad/',
    tiktok : 'https://www.tiktok.com/@glamsquad',
    linkedin: 'https://www.linkedin.com/company/glamsquad'
  }

  const { pathname } = useRouter()

  if (
    (!forceShow && pathname.includes('book')) ||
    pathname.includes('appointment-survey')
  ) {
    return null
  }

  return (
    <FooterContainer>
      <div className='footer-top'>
        <div className='app-section'>
          <div className='left-header'>
            <Header2>Book your glam on the go</Header2>
            <Text variant='medium'>Download the Glamsquad app</Text>
          </div>
          <a href={appUrl}>
            <AppStoreIcon />
          </a>
        </div>

        <div className='footer-right'>
          <div className='link-sections'>
            <div className='sections-container'>
              <FooterSection 
                title='Shop'
                sections={serviceUrls}
              />
              <FooterSection 
                title='About'
                sections={aboutUrls}
              />
              <FooterSection 
                title='Support'
                sections={supportUrls}
              />
            </div>
          </div>
          <div className='footer-social'>
            <div><a href={socialLinks.instagram}><InstagramIcon/></a></div>
            <div><a href={socialLinks.facebook}><FacebookIcon /></a></div>
            <div><a href={socialLinks.tiktok}><TikTokIcon /></a></div>
            <div><a href={socialLinks.linkedin}><LinkedInIcon /></a></div>
          </div>
        </div>
      </div>

      <div className='footer-bottom-logo'>
        <img src='/images/new-gs-wordmark-logo.svg' width='358' height='49.219' alt='glamsquad logo' />
      </div>
    </FooterContainer>
  )
}

const FooterSection = ({ title, sections }) => {
  const [ open, setOpen ] = useState(false)

  const toggle = () => {
    setOpen(s => !s)
  }

  return (
    <FooterLinkSection>
      <div onClick={toggle} className='section-header-wrapper'>
        <Header3>{title}</Header3>
        <button style={{ color: '#000' }}>{!open ? '+' : '-'}</button>
      </div>

      <ul className={`${open ? '' : 'hidden'}`}>
        { sections.map(section => (
          <li key={section.name}>
            { section.link && (
              <Link href={section.link}><Text>{section.name}</Text></Link>
            )}

            { section.href && (
              <Link legacyBehavior href={section.href}><a target='_blank'><Text>{section.name}</Text></a></Link>
            )}
          </li>
        ))}
      </ul>
    </FooterLinkSection>
  )
}

export default Footer
